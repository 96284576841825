import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'//// token存储
const ManagementStatus = 'ManagementStatus'// 是否具有管理员权限存储
const EntryPermissions = 'EntryPermissions'// 管理员权限列表存储
const level_info = 'level_info'// 管理员权限等级存储

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getManagementStatus() {
    return Cookies.get(ManagementStatus)
}

export function setManagementStatus(state) {
    return Cookies.set(ManagementStatus, state)
}

export function removeManagementStatus() {
    return Cookies.remove(ManagementStatus)
}

export function getEntryPermissions() {
    return Cookies.get(EntryPermissions)
}

export function setEntryPermissions(arr) {
    return Cookies.set(EntryPermissions, arr)
}

export function removeEntryPermissions() {
    return Cookies.remove(EntryPermissions)
}

export function getlevel_info() {
    return Cookies.get(level_info)
}

export function setlevel_info(arr) {
    return Cookies.set(level_info, arr)
}

export function removelevel_info() {
    return Cookies.remove(level_info)
}
