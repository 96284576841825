import request from "@/utils/request";

export function change_password(data) {
  return request({
    url: "/change_password",
    method: "post",
    data: data,
  });
}
export function update_user(data) {
  return request({
    url: "/update_user",
    method: "post",
    data: data,
  });
}
// 获取个人信息
export function getInfo() {
  return request({
    url: "/user_details",
    method: "get",
  });
}
// 获取个人比赛信息
export function entry_list() {
  return request({
    url: "/entry_list",
    method: "post",
  });
}
// 搜索比赛
export function MatchList(data) {
	return request({
	url: "/search/MatchList",
	method: "post",
	data: data,
	});
}
// 搜索比赛
export function upload_code(data, id, code_type) {
	return request({
	url: "/upload_code/" + id + "/" + code_type,
	method: "post",
	data: data,
	});
}

// 个人查看查重结果
export function duplicate_check_one(data) {
	return request({
	url: "/duplicate_check_one",
	method: "post",
	data: data,
	});
}

// 个人生成查重结果
export function create_check_result_user(data) {
	return request({
	url: "/create_check_result_user",
	method: "post",
	data: data,
	});
}
// 个人实验空间列表
export function space_list(data) {
	return request({
	url: "/space_list",
	method: "post",
	data: data,
	});
}
// 进入实验空间
export function enter_space(data) {
	return request({
	url: "/enter_space",
	method: "post",
	data: data,
	});
}
// 创建实验空间
export function create_space(data) {
	return request({
	url: "/create_space",
	method: "post",
	data: data,
	});
}
// 是否创建实验空间
export function have_space(data) {
	return request({
	url: "/have_space",
	method: "post",
	data: data,
	});
}
// 查看可创建的空间
export function not_create_space(data) {
	return request({
	url: "/not_create_space",
	method: "post",
	data: data,
	});
}
// 修改个人空间
export function update_space(data) {
	return request({
	url: "/update_space",
	method: "post",
	data: data,
	});
}
// 重启在线开发系统（超级管理员）
export function restart_open_code(data) {
	return request({
	url: "/restart_open_code",
	method: "post",
	data: data,
	});
}